// const ValentinesDay = () => (
//   <div
//     className='overflow-hidden text-white 2'
//     style={{
//       backgroundColor: '#fad4e2',
//       backgroundPosition: 'center',
//       backgroundSize: 'cover',
//       backgroundImage: 'url("/images/love-mailbox.jpg")'
//     }}
//   >
//     <div className='container mx-auto'>
//       <div className='flex flex-wrap content-center justify-center'>
//         <div className='w-9/12'>
//           <div className='flex flex-col items-center md:flex-row'>
//             <div className='flex flex-col items-start justify-center w-full pt-12 pb-24 lg:w-1/2'>
//               <h1 className='my-4 text-xl font-bold'>
//                 Presenter till Alla hjärtans dag {new Date().getFullYear()}
//               </h1>
//               <p className='mb-4 leading-normal'>
//                 Årets mest romantiska dag är snart här, Alla hjärtans dag! Vi har samlat ihop
//                 passande presenter att ge bort till den du älskar. Kanske ett doftljus eller en
//                 personlig present?
//               </p>
//               <a
//                 href='/presenter/presenter-till-alla-hjartans-dag/'
//                 className='px-4 py-2 text-white bg-pink-300 border border-pink-500 rounded shadow hover:text-black'
//               >
//                 Hitta kärlekspresenter
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// )

import Hero from './hero';
import { baseUrl } from '../lib/url';

const ValentinesDay = () => (
  <Hero
    title={
      <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
        <span className="block">Presenter till</span>
        <span className="text-pink-600 block">Alla hjärtans dag</span>
      </h1>
    }
    text={`Årets mest romantiska dag är snart här, Alla hjärtans dag! Vi har samlat ihop passande presenter att ge bort till den du älskar. Kanske ett doftljus eller en personlig present?`}
    link2={{
      text: 'Visa nya presenter',
      url: '#nyapresenter',
      className: 'text-pink-700 bg-pink-100 hover:bg-pink-200',
    }}
    link1={{
      text: 'Hitta kärlekspresenter',
      url: baseUrl('/presenter/presenter-till-alla-hjartans-dag/'),
      className: 'text-white bg-pink-600 hover:bg-pink-700',
    }}
    img="/images/love-mailbox.jpg"
    alt="Alla hjärtans dag"
  />
);

export default ValentinesDay;
