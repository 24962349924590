const BuyButton = ({ children, url, className = '' }) => (
  <a
    href={url}
    onClick={(event) => {
      if (typeof gtag === 'function') {
        gtag('event', 'click', {
          event_category: 'outbound',
          event_label: url,
          transport_type: 'beacon',
        });
      }
    }}
    target="_blank"
    rel="nofollow noopener noreferrer"
    className={`px-4 py-2 border border-transparent text-base font-medium rounded-md text-green-700 bg-green-100 hover:bg-green-200 md:py-4 md:text-sm ${className}`}
  >
    {children}
  </a>
);

export default BuyButton;
