import Hero from './hero'
import { baseUrl } from '../lib/url'

const Xmas = () => (
  <Hero
    title={
      <h1 className='text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl'>
        <span className='block'>Julklappar och presenter till dina</span>
        <span className='text-red-600 block'>nära och kära</span>
      </h1>
    }
    text='Här hittar du tips på bra julklappar och presenter för din flickvän, pojkvän, fru eller make. Allt under samma tak.'
    link2={{
      text: 'Visa nya presenter',
      url: '#nyapresenter',
      className: 'text-red-700 bg-red-100 hover:bg-red-200'
    }}
    link1={{
      text: 'Visa våra julklappstips',
      url: baseUrl('/presenter/julklappstips/'),
      className: 'text-white bg-red-600 hover:bg-red-700'
    }}
    img='/images/xmas.jpg'
    alt='Julklappar'
  />
)

export default Xmas
