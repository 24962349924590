import Hero from './hero';
import Link from '../components/link';
import { baseUrl } from '../lib/url';

const Standard = () => (
  <Hero
    title={
      <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
        <span className="block">Hitta presenter till familjen, släkt, vänner,</span>
        <span className="text-pink-600 block">tillfällen och högtider</span>
      </h1>
    }
    text={
      <span>
        Här hittar du roliga, oväntade och personliga presenter till din{' '}
        <Link target="_self" to="/presenter/presenter-till-flickvan/">
          flickvän
        </Link>
        ,{' '}
        <Link target="_self" to="/presenter/presenter-till-pojkvan/">
          pojkvän
        </Link>
        ,{' '}
        <Link target="_self" to="/presenter/presenter-till-henne/">
          fru
        </Link>{' '}
        eller{' '}
        <Link target="_self" to="/presenter/presenter-till-honom/">
          make
        </Link>
        . Vem letar du presenter till idag?
      </span>
    }
    link1={{
      text: 'Visa nya presenter',
      url: '#nyapresenter',
      className: 'text-white bg-pink-600 hover:bg-pink-700',
    }}
    link2={{
      text: 'Visa alla presenter',
      url: baseUrl('/presenter/alla-presenttips/'),
      className: 'text-pink-700 bg-pink-100 hover:bg-pink-200',
    }}
  />
);

export default Standard;
