import ImageGrid from '../imageGrid';
import { baseUrl } from '../../lib/url';

const LinkList = ({
  color = 'cutty-sark',
  items = [],
  linkColor = 'gray',
  textColor = 'white',
  images = [],
}) => (
  <div className="flex flex-wrap pb-0 lg:pb-10 px-5 pt-10">
    {items.map((i, ii) => (
      <div key={ii} className={`w-full lg:w-1/${items.length} px-2`}>
        {images.length ? (
          <ImageGrid noPadding={true} images={[images[ii]]} className="" />
        ) : (
          <h3 className={`bg-${color} rounded text-${textColor} text-center p-2`}>{i.title}</h3>
        )}
        <ul className="pt-5">
          {i.items.map((i1, i2) => (
            <li key={i2} className="mb-4">
              <span className="ml-3">
                <span className="text-gray-500">&bull; </span>
                <a className={`pl-2 text-${linkColor}-700 hover:underline`} href={baseUrl(i1.url)}>
                  {i1.title}
                </a>
              </span>
            </li>
          ))}
        </ul>
      </div>
    ))}
  </div>
);

export default LinkList;
