const Hero = (hero) => {
  const names = ['easter', 'mothersDay', 'standard', 'valentinesDay', 'sale', 'xmas'];
  const Components = {};

  names.forEach((n) => {
    Components[n] = require(`./${n}`).default;
  });

  const date = new Date();

  switch (date.getMonth()) {
    case 0:
      if (date.getDate() >= 15) {
        hero = 'valentinesDay';
      } else {
        hero = 'standard';
      }
      break;
    case 1:
      if (date.getDate() < 15) {
        hero = 'valentinesDay';
      } else {
        hero = 'standard';
      }
      break;
    case 10:
      if (date.getDate() > 20 && date.getDate() < 30) {
        hero = 'xmas';
        // should be 'sale' as in black friday.
        // but no time to write black friday logic now.
      } else if (date.getDate() > 30) {
        hero = 'xmas';
      }
      break;
    case 11:
      if (date.getDate() < 25) {
        hero = 'xmas';
      }
      break;
    default:
      break;
  }

  // todo: alla andra heros
  return Components[hero];
};

export default Hero;
