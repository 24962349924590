const colors = {
  bg: {
    700: {
      red: 'bg-red-700'
    }
  },
  text: {
    700: {
      red: 'text-red-700'
    }
  }
}
const Hero = ({
  title = null,
  text = null,
  link1 = null,
  link2 = null,
  img = '/images/pink-gift.jpg',
  alt = 'Presenter'
}) => (
  <div className='relative bg-white overflow-hidden'>
    <div className='max-w-7xl mx-auto'>
      <div className='relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32'>
        <svg
          className='hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2'
          fill='currentColor'
          viewBox='0 0 100 100'
          preserveAspectRatio='none'
          aria-hidden='true'
        >
          <polygon points='50,0 100,0 50,100 0,100' />
        </svg>

        <div>
          <div className='relative pt-6 px-4 sm:px-6 lg:px-8' />
        </div>

        <main className='mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28'>
          <div className='sm:text-center lg:text-left'>
            {title}
            <p className='mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0'>
              {text}
            </p>
            <div className='mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start'>
              <div className='rounded-md shadow'>
                <a
                  href={link1.url}
                  className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md md:py-4 md:text-lg md:px-10 ${link1.className}`}
                >
                  {link1.text}
                </a>
              </div>
              <div className='mt-3 sm:mt-0 sm:ml-3'>
                {link2 && (
                  <a
                    href={link2.url}
                    className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md md:py-4 md:text-lg md:px-10 ${link2.className}`}
                  >
                    {link2.text}
                  </a>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <div className='lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2'>
      <img
        className='h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full lazyload'
        src={img}
        alt={alt}
      />
    </div>
  </div>
)

export default Hero
