import { LazyLoadImage } from 'react-lazy-load-image-component';

const defaultStyle = {
  backgroundColor: '#71b7af',
  color: '#fff',
};

const Title = ({ h1, title, color = 'gray' }) =>
  h1 ? (
    <h1
      className={`text-4xl tracking-tight font-extrabold text-${color}-900 sm:text-5xl md:text-6xl text-center`}
    >
      <span className="block xl:inline">{title}</span>
    </h1>
  ) : (
    <h1
      className={`text-3xl tracking-tight font-extrabold text-${color}-900 sm:text-xl md:text-2xl text-center`}
    >
      <span className="block xl:inline">{title}</span>
    </h1>
  );

const Content = ({ h1, subtitle, title, withImage = false }) => (
  <div
    className={`mt-0 ${
      withImage ? 'mb-5 lg:mb-0 lg:w-1/3 text-center lg:text-left' : 'pb-16 m-10'
    }`}
  >
    <div className="pt-12">
      <Title h1={h1} title={title} />
    </div>
    {/* {h1 ? (
      <h1 className={`text-4xl pt-16 text-center`}>{title}</h1>
    ) : (
      <h2 className={`text-4xl pt-16 text-center`}>{title}</h2>
    )} */}
    <div className="flex justify-center lg:block pt-10">
      {subtitle && (
        <p className={`${withImage ? 'w-2/3 lg:w-full' : ''} text-center`}>{subtitle}</p>
      )}
    </div>
  </div>
);

const Hero2 = ({
  className = '',
  id = null,
  style = defaultStyle,
  image = null,
  imageProps = {},
  title,
  subtitle,
  h1 = false,
}) => (
  <div id={id} className={`${className}`} style={className ? {} : style}>
    <div className="container mx-auto">
      {image ? (
        <div className="flex flex-col justify-center lg:flex-row">
          <div className="flex justify-center hidden mx-10 lg:block">
            <LazyLoadImage src={image} alt={title} {...imageProps} />
          </div>
          <Content h1={h1} subtitle={subtitle} title={title} withImage />
          <div className="flex justify-center block lg:hidden">
            <LazyLoadImage src={image} alt={title} {...imageProps} />
          </div>
        </div>
      ) : (
        <Content h1={h1} subtitle={subtitle} title={title} />
      )}
    </div>
  </div>
);

// const colors = {
//   gray: {
//     bgColor: '',
//     textColor: 'text-gray-500',
//   }
// }

const Hero = ({
  id = '',
  title,
  subtitle,
  h1 = false,
  className = '',
  bgColor = 'gray',
  textColor = 'gray',
  border = true,
  borderColor = 'gray',
  textCenter = true,
  image = '',
}) => (
  <div
    id={id}
    className={`relative bg-white overflow-hidden ${
      border ? `border-t border-b border-solid border-${borderColor}-100` : ''
    }`}
  >
    <div className={image ? 'max-w-7xl mx-auto' : ''}>
      <div className={image ? 'relative z-10 bg-white lg:max-w-2xl lg:w-full' : ''}>
        <div className={`pt-16 pb-16 bg-${bgColor}-300`}>
          <div className="container mx-auto">
            <div className="m-10 mb-0 mt-0">
              {h1 ? (
                <h1
                  className={`text-3xl tracking-tight font-extrabold text-${textColor}-900 sm:text-xl md:text-2xl ${
                    textCenter ? 'text-center' : ''
                  }`}
                >
                  <span className="">{title}</span>
                </h1>
              ) : (
                <h2
                  className={`text-3xl tracking-tight font-extrabold text-${textColor}-900 sm:text-xl md:text-2xl ${
                    textCenter ? 'text-center' : ''
                  }`}
                >
                  <span className="">{title}</span>
                </h2>
              )}
              <p
                className={`mt-4 text-xl text-${textColor}-500 leading-8 ${
                  textCenter ? 'text-center' : ''
                }`}
              >
                {subtitle}
              </p>
            </div>
          </div>
        </div>
      </div>
      {image && (
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full lazyload"
            src={image}
            alt={title}
          />
        </div>
      )}
    </div>
  </div>
);

export default Hero;
