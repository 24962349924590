import React from 'react';
import Head from 'next/head';
import { Smile, Calendar } from 'react-feather';
import { products, presenter } from '../data';
import Products from '../components/products';
import Hero from '../components/hero';
import LinkList from '../components/linkList';
import Link from '../components/link';
import ImageGrid from '../components/imageGrid';
import { baseUrl } from '../lib/url';
import requireHero from '../heros';
import 'array-flat-polyfill';

class Index extends React.Component {
  static async getInitialProps() {
    const randomProducts = [];

    for (let i = 0, l = 8; i < l; i++) {
      randomProducts.push(products[Math.floor(Math.random() * products.length)]);
    }

    return {
      total: products.length,
      products: randomProducts,
    };
  }

  render() {
    const { products, total } = this.props;
    const title = `Present & presenttips - Köp din presenter på nätet | Alla Presenter`;

    const description =
      'På allapresenter.com har vi presenter för alla åldrar och tillfällen. Köp en fin present som blir uppskattad av mottagaren och som visar din uppskattning och tacksamhet.';

    const ldjson = `{
      "@context": "https://schema.org",
      "@type": "WebSite",
      "name": "${title}",
      "description": "${description}",
      "url": "${baseUrl('/')}",
      "image": "${baseUrl('/images/logo.png')}"
    }`;

    const TopHero = requireHero('standard');

    const priceCategories = presenter.kategorier.items
      .filter((p) => p.type === 'price')
      .pop().items;

    return (
      <div>
        <Head>
          <title>{title}</title>
          <link rel="canonical" href={baseUrl('/')} />
          <meta name="description" content={description} />
          <meta property="og:locale" content="sv_SE" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={baseUrl('/')} />
          <meta property="og:site_name" content={title} />
          <meta property="og:image" content={baseUrl('/images/logo.png')} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={baseUrl('/images/logo.png')} />
          <meta name="twitter:card" content="summary" />
          <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: ldjson }} />
        </Head>

        <TopHero />

        {/* Mottagare */}
        <Hero
          bgColor="white"
          title="Vem letar du presenter till?"
          subtitle="Hitta en present som gör någon glad! Vi har presenter till familjen, släkt och vänner."
        />
        <div className="bg-white">
          <div className="container mx-auto mb-2">
            <LinkList
              items={presenter.mottagare.items}
              linkColor="pink"
              images={[
                {
                  title: 'Till de yngre',
                  image: '/images/grid/barn.jpg',
                  url: baseUrl('/presenter/presenter-till-barn/'),
                },
                {
                  title: 'Till henne',
                  image: '/images/grid/henne.jpg',
                  url: baseUrl('/presenter/presenter-till-henne/'),
                },
                {
                  title: 'Till honom',
                  image: '/images/grid/honom.jpg',
                  url: baseUrl('/presenter/presenter-till-honom/'),
                },
              ]}
            />
          </div>
        </div>

        {/* Tillfällen */}
        <Hero
          bgColor="white"
          title="Tillfällen och högtider"
          subtitle="Vi har presenter och presenttips till olika tillfällen och högtider. Det finns nått för alla!"
        />
        <ImageGrid
          images={presenter.tillfallen.items
            .concat({
              items: [
                presenter.kategorier.items[1].items[0],
                presenter.kategorier.items[1].items[1],
              ],
            })
            .map((i) => i.items)
            .flat()
            .map((i) => {
              i.image = baseUrl(
                `/images/grid/${i.url
                  .split('/')
                  .filter((i) => i)
                  .pop()
                  .replace('presenter', '')
                  .replace('-till', '')
                  .replace(/^\-/, '')}.jpg`,
              );
              return i;
            })}
        />

        {/* Popular products */}
        <Hero
          id="nyapresenter"
          title="Nya presenter och presenttips"
          subtitle="Vet du inte riktigt vad du letar efter? Här listar vi det senaste presenterna vi fått in!"
          bgColor="white"
        />
        <Products products={products} />

        <div className="bg-white">
          <div className="container mx-auto">
            <div className="flex justify-center mb-10">
              <div className="rounded-md ">
                <a
                  href={baseUrl('/presenter/alla-presenttips/')}
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-pink-700 bg-pink-100 hover:bg-pink-200 md:py-4 md:text-sm md:px-10"
                >
                  Visa alla presenter
                </a>
              </div>
            </div>
          </div>
        </div>

        <section className="w-full mt-20 lg:px-10">
          <div className="container mx-auto">
            <div className="bg-white px-4 sm:px-6 lg:px-8">
              <div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
                <div>
                  <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl text-center">
                    Bra presenter
                  </h2>
                </div>
              </div>
              <div className="mt-6 lg:mt-12 grid gap-10 lg:gap-16 pt-6 lg:pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12 border-t border-solid border-gray-300">
                <div>
                  <p className="text-xl font-semibold text-gray-900">Bra presenter till honom</p>
                  <p className="mt-3 text-base text-gray-500">
                    Letar du efter en bra{' '}
                    <Link target="_self" to="/presenter/presenter-till-honom/">
                      presenter till honom
                    </Link>{' '}
                    eller{' '}
                    <Link target="_self" to="/presenter/presenter-till-man/">
                      presenter till män
                    </Link>
                    , så har vi samlat ett flertal presenttips i olika kategorier som passar killar.
                    Du kanske letar du efter en{' '}
                    <Link target="_self" to="/presenter/presenter-inflyttning/">
                      inflyttningspresent
                    </Link>{' '}
                    eller en{' '}
                    <Link target="_self" to="/presenter/presenter-till-alla-hjartans-dag/">
                      alla hjärtans dag present
                    </Link>
                    . Vi hoppas du kommer hitta något!
                  </p>
                </div>
                <div>
                  <p className="text-xl font-semibold text-gray-900">Bra presenter till henne</p>
                  <p className="mt-3 text-base text-gray-500">
                    Visst kan det vara knepigt att hitta en bra{' '}
                    <Link target="_self" to="/presenter/presenter-till-henne/">
                      presenter till henne
                    </Link>{' '}
                    eller{' '}
                    <Link target="_self" to="/presenter/presenter-till-kvinnor/">
                      presenter till kvinnor
                    </Link>
                    ? Vi har samlat ett flertal presenttips i olika kategorier som passar tjejer.
                    Kanske letar du efter en{' '}
                    <Link target="_self" to="/presenter/presenter-skonhet-och-halsa/">
                      presenter inom skönhet och hälsa
                    </Link>{' '}
                    eller en{' '}
                    <Link target="_self" to="/presenter/presenter-till-alla-hjartans-dag/">
                      alla hjärtans dag present
                    </Link>
                    . Vi hoppas du kommer hitta något!
                  </p>
                </div>
                <div>
                  <p className="text-xl font-semibold text-gray-900">Bra presenter till pojkvän</p>
                  <p className="mt-3 text-base text-gray-500">
                    Letar du efter en bra present tll din kille eller pojvän? Tänk på vilka
                    intressen han har och vad han gillar att göra så blir det lättare att hitta
                    något som passar. Kanske är det alla hjärtans dag snart eller födelsedag? Vi har
                    presenter för alla tillfällen. Tips på kategorier:{' '}
                    <Link target="_self" to="/presenter/presenter-till-pojkvan/">
                      presenter till pojkvän
                    </Link>
                    ,{' '}
                    <Link target="_self" to="/presenter/presenter-till-alla-hjartans-dag/">
                      alla hjärtans dag present
                    </Link>
                    ,{' '}
                    <Link target="_self" to="/presenter/presenter-fodelsedagar/">
                      födelsedagspresent
                    </Link>
                    .
                  </p>
                </div>
                <div>
                  <p className="text-xl font-semibold text-gray-900">Bra presenter till flickvän</p>
                  <p className="mt-3 text-base text-gray-500">
                    Letar du efter en bra present tll din tjej eller flickvän? Det är viktigt att
                    tänka på vem man köper presenten till så det inte blir vad som helst. Kanske är
                    det alla hjärtans dag snart eller bara vill ge henne något fint? Vi har
                    presenter för alla tillfällen. Tips på kategorier:{' '}
                    <Link target="_self" to="/presenter/presenter-till-flickvan/">
                      presenter till flickvän
                    </Link>
                    ,{' '}
                    <Link target="_self" to="/presenter/presenter-till-alla-hjartans-dag/">
                      alla hjärtans dag present
                    </Link>
                    ,{' '}
                    <Link target="_self" to="/presenter/presenter-klader-och-accessoarer/">
                      presenter inom kläder och accessoarer
                    </Link>
                    .
                  </p>
                </div>
                <div>
                  <p className="text-xl font-semibold text-gray-900">Bra presenter till mamma</p>
                  <p className="mt-3 text-base text-gray-500">
                    Köp en bra present till din mamma, tänk på vad hon har gjort för dig genom livet
                    och vad hon fortfarande gör för dig. Visa att du uppskattar henne och köp inte
                    något billigt. Du kanske har syskon som du kan köpa en present tillsammans med?
                    Vi har massivs av bra presenttips som hjälper dig at hitta den perfekta
                    presenten idag. Tips på kategorier:{' '}
                    <Link target="_self" to="/presenter/presenter-till-mamma/">
                      presenter till mamma
                    </Link>
                    ,{' '}
                    <Link target="_self" to="/presenter/presenter-skonhet-och-halsa/">
                      presenter inom skönhet och hälsa
                    </Link>
                    ,{' '}
                    <Link target="_self" to="/presenter/presenter-till-mors-dag/">
                      presenter till mors dag
                    </Link>
                    .
                  </p>
                </div>
                <div>
                  <p className="text-xl font-semibold text-gray-900">Bra presenter till pappa</p>
                  <p className="mt-3 text-base text-gray-500">
                    Vi har många bra presenttips som hjälper dig att hitta den perfekta presenten
                    till pappa. Tänk på vilka intressen han har och vad han gillar att göra så blir
                    det lättare att hitta något som passar. Genom att tänka på dessa aspekter och
                    köpa en produkt utefter detta kommer du att köpa något som blir mycket
                    uppskattat av din pappa. Tips på kategorier:{' '}
                    <Link target="_self" to="/presenter/presenter-till-pappa/">
                      presenter till pappa
                    </Link>
                    ,{' '}
                    <Link target="_self" to="/presenter/presenter-till-fars-dag/">
                      presenter till fars dag
                    </Link>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="w-full mt-20 lg:px-10">
          <div className="container mx-auto">
            <div className="bg-white px-4 sm:px-6 lg:px-8">
              <h3 className="text-xl tracking-tight font-extrabold text-gray-900 sm:text-1xl">
                Vad ska en present kosta?
              </h3>
              <p className="mt-3 text-base text-gray-500">
                Ibland behöver man leta efter presenter utifrån pris och vi med hjälp av våra
                priskategorier så hoppas vi att du kommer kunna hitta en present som passar både dig
                och mottagaren:{' '}
                {priceCategories.map((item, index) => (
                  <span key={index}>
                    <Link target="_self" to={item.url}>
                      {item.title}
                    </Link>
                    {index === priceCategories.length - 1 ? '' : ', '}
                  </span>
                ))}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Index;
