import BuyButton from '../buyButton';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { baseUrl } from '../../lib/url';

const Product = ({ image, title, description, price, store, link, url }) => (
  <a href={baseUrl(url)}>
    <div>
      <div className="relative">
        <div className="relative w-full h-72 rounded-lg overflow-hidden">
          <img
            src={image}
            alt={title}
            className="w-full h-full object-center object-cover lazyload"
          />
        </div>
        <div className="relative mt-4">
          <h3 className="text-sm font-medium text-gray-900" style={{ minHeight: 40 }}>
            {title}
          </h3>
          <div className="flex flex-wrap">
            <p
              className="mt-1 text-sm text-gray-500 overflow-hidden"
              style={{ minHeight: '100px' }}
              dangerouslySetInnerHTML={{
                __html: (
                  description.substring(0, 120) + (description.length > 120 ? '...' : '')
                ).trim(),
              }}
            />
          </div>
        </div>
        <div className="absolute top-0 inset-x-0 h-72 rounded-lg p-4 flex items-end justify-end overflow-hidden">
          <div
            aria-hidden="true"
            className="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-black opacity-50"
          />
          <p className="relative text-lg font-semibold text-white">{price} kr</p>
        </div>
      </div>
      <div className="mt-6">
        <BuyButton
          url={`https://allapresenter.com${link}/`}
          className="w-full flex justify-center items-center"
        >
          Köp nu
        </BuyButton>
        {/* <a
        href="#"
        className="relative flex bg-gray-100 border border-transparent rounded-md py-2 px-8 items-center justify-center text-sm font-medium text-gray-900 hover:bg-gray-200"
      >
        Add to bag<span className="sr-only">, Zip Tote Basket</span>
      </a> */}
      </div>
    </div>
  </a>
);

const Products = ({ products = [] }) => (
  <div className="container mx-auto">
    <div className="bg-white">
      <div className="max-w-2xl mx-auto py-6 px-4 sm:py-12 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="mt-8 grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          {products.map((product, pi) => (
            <Product {...product} key={pi} />
          ))}
        </div>
      </div>
    </div>
    {/* {products.map((product, pi) => (
        <div key={pi} className="w-full md:w-1/3 xl:w-1/3 p-6 mb-5 hidden">
          <a href={`https://allapresenter.com${product.url}`}>
            <div
              className="overflow-hidden object-contain hidden lg:block"
              style={{ paddingBottom: '56,25%', minHeight: 352 }}
            >
              <div
                className="flex content-center"
                style={{
                  lineHeight: '352px',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                }}
              >
                <LazyLoadImage
                  className="hover:grow hover:shadow-lg"
                  src={product.image}
                  alt={product.title}
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                  }}
                />
              </div>
            </div>
            <LazyLoadImage
              className="hover:grow hover:shadow-lg block lg:hidden"
              src={product.image}
              alt={product.title}
            />
            <div className="pt-3 flex items-center justify-between pb-8">
              <p className="text-gray-500 h-6 text-lg">{product.title}</p>
            </div>
            <div>
              <p className="font-bold h-4">{product.price} kr</p>
              <p
                className="pt-2"
                style={{ minHeight: '5rem' }}
                dangerouslySetInnerHTML={{
                  __html: (
                    product.description.substring(0, 120) +
                    (product.description.length > 120 ? '...' : '')
                  ).trim(),
                }}
              ></p>
            </div>
          </a>
          <div className="pt-5 flex items-center justify-between pin-b">
            <BuyButton url={`https://allapresenter.com${product.link}/`}>
              Köp hos {product.store.name}
            </BuyButton>
          </div>
        </div>
      ))}
    </div> */}
  </div>
);

export default Products;
