const MothersDay = () => (
  <div
    className='overflow-hidden text-white 2'
    style={{
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundImage: 'url("/images/hero/mors-dag.jpg")'
    }}
  >
    <div className='container mx-auto'>
      <div className='flex flex-wrap content-center justify-center'>
        <div className='w-9/12'>
          <div className='flex flex-col items-center md:flex-row'>
            <div className='flex flex-col items-start justify-center w-full pt-12 pb-24 lg:w-1/2'>
              <h1 className='my-4 text-xl font-bold lg:text-3xl text-shadow'>
                Presenter till Mors dag
              </h1>
              <p className='mb-4 leading-normal text-shadow'>
                Letar du efter presenttips till mors dag? Vi har samlat alla våra morsdagpresenter
                under ett och samma tak, allt från roliga till personliga presenter som kan
                överraska och ge glädje till mamma.
              </p>
              <a
                href='/presenter/presenter-till-mors-dag/'
                className='px-4 py-2 text-black bg-white rounded shadow hover:text-black hover:shadow-lg'
              >
                Visa morsdagpresenter
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default MothersDay
